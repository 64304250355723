import React from 'react';
import { CustomCurrencyTextField, CustomRadioGroup, CustomTextField } from '@components';

import { costTypeOptions } from '@constants';
import { notAllowNegative } from '@utils';
import { IMilestone } from '@interfaces';

const name = (lineItem: Partial<IMilestone>) => ({
  Component: CustomTextField,
  componentProps: {
    label: 'Line item name',
    required: true,
    inputProps: {
      'data-cy': 'edit_line_item_modal__name_input',
    },
  },
  modelProps: {
    initValue: lineItem?.name || '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  },
});

const cost_type = (lineItem: Partial<IMilestone>) => ({
  Component: ({ field, options, dataTestName, ...props }) => (
    <CustomRadioGroup
      value={field.value}
      onChange={field.handleChange}
      options={options}
      dataTestName={dataTestName}
      {...props}
    />
  ),
  componentProps: {
    dataTestName: 'edit_line_item_modal__cost_type__radio_group',
    options: costTypeOptions,
  },
  modelProps: {
    initValue: lineItem?.cost_type || costTypeOptions[0].value,
  },
});

const requested_revised_estimate = () => ({
  Component: CustomCurrencyTextField,
  componentProps: {
    label: 'Requested scheduled value ($)',
    required: true,
    inputProps: {
      'data-cy': 'edit_line_item_modal__estimate_input',
      isAllowed: notAllowNegative,
    },
  },
  modelProps: {
    initValue: '',
    validationRule: (value) => Boolean(+value?.trim()),
    validateOnChange: true,
  },
});

const approved_adjustments = () => ({
  Component: CustomCurrencyTextField,
  componentProps: {
    label: 'Revised scheduled value ($)',
    required: true,
    inputProps: {
      'data-cy': 'edit_line_item_modal__estimate_input',
    },
  },
  modelProps: {
    initValue: '',
    validationRule: (value) => Boolean(+value?.trim()),
    validateOnChange: true,
  },
});

const original_estimate = () => ({
  Component: CustomCurrencyTextField,
  componentProps: {
    label: 'Original scheduled value ($)',
    required: true,
    inputProps: {
      isAllowed: notAllowNegative,
      inputProps: {
        'data-cy': 'edit_line_item_modal__original_estimate',
      },
    },
  },
  modelProps: {
    initValue: '',
    validationRule: (value) => Boolean(+value?.trim()),
    validateOnChange: true,
  },
});

const prefunding_cost = () => ({
  Component: CustomCurrencyTextField,
  componentProps: {
    label: 'Prefunding cost ($)',
    required: true,
    inputProps: {
      isAllowed: notAllowNegative,
      inputProps: {
        'data-cy': 'edit_line_item_modal__prefunding_cost',
      },
    },
  },
  modelProps: {
    initValue: '',
    validationRule: (value) => Boolean(+value?.trim()),
    validateOnChange: true,
  },
});

export default {
  name,
  cost_type,
  requested_revised_estimate,
  approved_adjustments,
  original_estimate,
  prefunding_cost,
};
