import { CellRenderWithStaticError, MilestoneListColumnTypeV2 } from './common';
import { getEllipsisStyle, isAllowed } from '@utils';
import { ICostType, IMilestone, PermissionNamesEnums } from '@interfaces';
import React from 'react';
import { GridEditInputCell } from '@mui/x-data-grid';
import { Stack, Tooltip, Typography } from '@mui/material';
import { colors } from '@theme';
import { InfoIcon } from '@svgAsComponents';
import { LabelAndValue } from 'src/components/LabelAndValue';

const ItemNameFormatter = ({ value, row }: { value: string; row: IMilestone }) =>
  row.localNew ? (
    <Stack alignItems="center" flexDirection="row">
      <Tooltip title={row.name?.length > 25 ? row.name : ''}>
        <Typography component="div" sx={getEllipsisStyle('100%')} variant="body3">
          <Typography color={colors.status.information.medium} variant="body3SemiBold">
            NEW{' '}
          </Typography>
          {row.name}
        </Typography>
      </Tooltip>
      <Tooltip
        title={
          <Stack spacing={1}>
            <Typography variant="body3SemiBold">{row.name}</Typography>
            <LabelAndValue
              label={'Request #'}
              text={row.project_milestone?.change_request_number?.toString()}
            />
            <LabelAndValue label="Cost type" text={(row.cost_type as ICostType)?.display || '-'} />
          </Stack>
        }
      >
        <Stack ml={1} mt="2px">
          <InfoIcon size={14} />
        </Stack>
      </Tooltip>
    </Stack>
  ) : (
    value
  );

const nameV2: MilestoneListColumnTypeV2 = {
  field: 'name',
  headerName: 'Line Item',
  description: 'Line Item',
  headerClassName: 'text',
  headerAlign: 'left',
  align: 'left',
  cellClassName: 'text',
  flex: 1,
  resizable: false,
  editable: true,
  sortable: true,
  filterable: true,
  groupable: false,
  aggregable: true,
  hideable: false,
  minWidth: 200,
  valueFormatter: (value, row) => <ItemNameFormatter value={value} row={row} />,
  renderCell: (params) => (
    <CellRenderWithStaticError
      blockNavigationOnError={true}
      params={params}
      errorText="Please, fill the name or delete the row."
    />
  ),
  renderEditCell: (props) => <GridEditInputCell {...props} inputProps={{ maxLength: 256 }} />,
  editableByMilestone: (row) => !row.project_milestone && row.isEditableV2 && row.localNew,
  editableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
};

export default nameV2;
